<!-- 评论底部导航（表情，图片，键盘等） -->
<template>
  <div class="comment_editor_bottom">
    <div class="butns">
      <span class="face iconfont iconxiaolian" @click ="faceContent" ></span>
      <span class="picture iconfont icontupian"></span>
      <span class="c_e_b_empty"></span>
      <span class="koard iconfont iconai-keyboard"></span>
    </div>
    <!-- 表情区域 -->
    <div class="browBox" v-if="faceShow">
      <ul>
        <li v-for="(item,index) in faceList" :key="index" @click="getBrow(index)">{{item}}</li>
      </ul>
    </div>
          
  </div>
</template>

<script>
const enjoys = require('../../../public/static/emojis.json');
export default {
  data () {
    return {
      faceShow:false,
      faceList:[],
      getBrowString:""
    };
  },
  // props: ["textConent"],
  props:{
    ftextConent: {
      type: String
    }
  },
  methods:{
    //输入框的控制输入
    // 表情
    faceContent() {
      this.faceShow = !this.faceShow;
      if (this.faceShow == true) {
        for (let i in enjoys) {
          this.faceList.push(enjoys[i].char);
        }
      } else {
        this.faceList = [];
      }
    },
    // 获取用户点击之后的标签 ，存放到输入框内
    getBrow(index) {
      for (let i in this.faceList) {
        if (index == i) {
          this.getBrowString = this.faceList[index];
          // this.textConent += this.getBrowString;
        }
      }
    }
  }
}

</script>
<style lang='scss' scoped>
// 操作按钮
.comment_editor_bottom{
  width:100%;
  min-height:47px;
  border-top:1px solid rgba(244,244,244,1);
  position: fixed;
  left:0;
  bottom:0;
  background:#fff;
  .butns{
    height:47px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    span{
      width:30px;
      display:inline-block;
      font-size:23px;
    }
    .picture{
      padding:0 10px;
    }
    .c_e_b_empty{
      flex:1;
      height:47px;
    }
  }
  // 表情区域
  .browBox {
    width: 100%;
    height: 100px;
    background:rgba(244,244,244,1);
    overflow: scroll;
    ul {
      display: flex;
      flex-wrap: wrap;
      padding: 5px;
      li {
        width: 14%;
        font-size: 15px;
        list-style: none;
        text-align: center;
        padding:5px 0;
      }
    }
  }
  // 位置
  .browBoxLast{
    position: absolute;
    bottom:100px;
  }
}
</style>