<template>
  <div>
    <div v-for="(item, index) in articleLists" :key="index">
      <!-- 普通文章 -->
      <div class="info-item box-bottom" v-if="item.type == 1">
        <span class="info-title">{{item.title}}</span>
        <div class="info-item-img">
          <div v-for="(pic, picindex) in item.picture" :key="picindex">
            <img :src="pic" alt />
          </div>
        </div>
        <div class="info-item-bot" style="font-size: 12px">
          <div class="info-item-botleft">
            <span>{{item.author}}</span>
            <span>{{item.readNums}}阅读</span>
            <span>{{item.commentNums}}评论</span>
          </div>
          <span>{{item.time}}</span>
        </div>
      </div>
      <!-- 精贴和顶帖 -->
      <div class="info-item-two box-bottom" v-else>
        <div class="info-item-left">
          <span class="info-two-title">
            <span v-if="item.type == 2" class="list-liabel blueB">{{item.sign}}</span>
            <span v-else class="list-liabel redB">{{item.sign}}</span>
            {{item.title}}
          </span>
          <div class="info-item-two-bot" style="font-size: 12px">
            <span>{{item.author}}</span>
            <span>{{item.readNms}}阅读</span>
            <span>{{item.commentNums}}评论</span>
            <span>{{item.time}}</span>
          </div>
        </div>
        <div class="info-item-two-img">
          <div v-for="(pic, picindex) in item.picture" :key="picindex" :style="autoHight">
            <img :src="pic" alt />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  // 注释 ：type 1 ->普通帖 2：->精贴 3：顶帖
  data() {
    return {
      autoHight: {
        height: "75px"
      }
    };
  },
  props: {
    articleLists: {
      type: Array
    }
  }
};
</script>
<style lang="scss" scoped>
.info-item {
  width: 98%;
  padding: 0 10px;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: #ffffff;
  .info-title {
    font-size: 15px;
    font-weight: 500;
    color: #4c4c4c;
  }
  .info-item-img {
    width: 100%;
    display: flex;
    margin-top: 5px;
    margin-left: -5px;
    div {
      flex: 1;
      border-radius: 2.5px;
      margin-left: 5px;
      background: #ffffff;
      img {
        width: 100%;
      }
    }
  }
  .info-item-bot {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    color: #7d7d7d;
    font-size: 11px;
    justify-content: space-between;
    .info-item-botleft {
      width: 160px;
      display: flex;
      justify-content: space-between;
    }
  }
}
.info-item-two {
  width: 100%;
  height: 75px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  background: #ffffff;

  .info-two-title {
    width: 250px;
    font-size: 15px;
    color: #4c4c4c;
    font-weight: 500;
    .list-liabel {
      width: 18px;
      height: 9px;
      font-size: 12px;
      padding: 2px;
      border-radius: 4px;
      color: #ffffff;
    }
    .blueB {
      background: #007aff;
    }
    .redB {
      background: red;
    }
  }
  .info-item-left {
    width: 269px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .info-item-two-bot {
      width: 200px;
      display: flex;
      justify-content: space-between;
    }
  }
  .info-item-two-img {
    width: 114px;
    display: flex;
    margin-top: 5px;
    margin-left: -5px;
    div {
      width: 100%;
      height: 75px;
      border-radius: 5px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.box-bottom {
  border-bottom: 1px solid #f1f1f1;
}
</style>