<!-- 图文发帖页 -->
<template>
  <div class="content">
      <div class="body">
        <input type="text" placeholder="请输入文章标题" class="title">
        <!-- <div class="title" contenteditable="true"></div> -->
        <uploader v-model="fileList" multiple />
        <ArticleList></ArticleList>
        <Discuss></Discuss>
      </div>
  </div>
</template>

<script>
import Discuss from "@components/public/discuss";
import ArticleList from "@components/public/articleList";
import { Uploader } from 'vant';
export default {
  data () {
    return {
      fileList: [
        { url: 'https://img.yzcdn.cn/vant/leaf.jpg' },
        // Uploader 根据文件后缀来判断是否为图片文件
        // 如果图片 URL 中不包含类型信息，可以添加 isImage 标记来声明
        { url: 'https://cloud-image', isImage: true }
      ]
    };
  },
  components: {
    Discuss, Uploader, ArticleList
  },
}
</script>
<style lang='scss' scoped>
.content{
    padding:12px;
    .title{
      width:100%;
      height:47px;
      border: 0;
      border-bottom:1px solid rgba(244,244,244,1);
      margin-bottom:5px;
    }
}
</style>